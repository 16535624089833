<template>
<div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <v-nav-menu :navMenuItems="navMenuItems" parent=".layout--main" />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
        <div id="content-overlay" />

        <!-- Navbar -->
        <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">

            <the-navbar-horizontal :navColor="navbarColor" :navbarType="navbarType" :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

            <div style="height: 62px" v-if="navbarType === 'static'"></div>

            <h-nav-menu :class="[
          {'text-base' : isNavbarDark  && !isThemeDark},
          {'text-white'  : !isNavbarDark && isThemeDark}
        ]" :navMenuItems="navMenuItems" />
        </template>

        <template v-else>
            <the-navbar-vertical :navbarColor="navbarColor" :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />
        </template>

        <div class="content-wrapper">

            <div class="router-view">

                <div class="router-content">

                    <globalMessage :message="globalMessage"></globalMessage>

                    <transition :name="routerTransition">

                        <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                            <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                                <h2 class="mb-1">{{ routeTitle }}</h2>
                            </div>
                            <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />
                        </div>
                    </transition>

                    <div class="content-area__content">

                        <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                            <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
                        </back-to-top>

                        <transition :name="routerTransition" mode="out-in">
                            <router-view @changeRouteTitle="changeRouteTitle" :currentUser="currentUser" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <the-footer />
    </div>
</div>
</template>

<script>
import BackToTop from 'vue-backtotop'
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import {
    menuItems
} from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import globalMessage from '@/views/common/globalmessage.vue'
import {
    profileData
} from '@/store/api/profile'

export default {
    components: {
        BackToTop,
        HNavMenu,
        TheFooter,
        TheNavbarHorizontal,
        TheNavbarVertical,
        VNavMenu,
        globalMessage
    },
    data() {
        return {
            footerType: themeConfig.footerType || 'static',
            hideScrollToTop: themeConfig.hideScrollToTop,
            isNavbarDark: false,
            navbarColor: themeConfig.navbarColor || '#fff',
            navbarType: themeConfig.navbarType || 'floating',
            routerTransition: themeConfig.routerTransition || 'none',
            routeTitle: this.$route.meta.pageTitle,
            currentUser: null,
            navMenuItems: []
        }
    },
    watch: {
        '$route'() {
            this.routeTitle = this.$route.meta.pageTitle
        },
        isThemeDark(val) {
            const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
            this.updateNavbarColor(color)
        },
        '$store.state.mainLayoutType'(val) {
            this.setNavMenuVisibility(val)
        }
    },
    computed: {
        bodyOverlay() {
            return this.$store.state.bodyOverlay
        },
        contentAreaClass() {
            if (this.mainLayoutType === 'vertical') {
                if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
                else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
                else return 'content-area-full'
            } else return 'content-area-full'
        },
        footerClasses() {
            return {
                'footer-hidden': this.footerType === 'hidden',
                'footer-sticky': this.footerType === 'sticky',
                'footer-static': this.footerType === 'static'
            }
        },
        isAppPage() {
            return this.$route.meta.no_scroll
        },
        isThemeDark() {
            return this.$store.state.theme === 'dark'
        },
        layoutTypeClass() {
            return `main-${this.mainLayoutType}`
        },
        mainLayoutType() {
            return this.$store.state.mainLayoutType
        },
        navbarClasses() {
            return {
                'navbar-hidden': this.navbarType === 'hidden',
                'navbar-sticky': this.navbarType === 'sticky',
                'navbar-static': this.navbarType === 'static',
                'navbar-floating': this.navbarType === 'floating'
            }
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        isProgressing() {
            return this.$store.state.isProgressing
        },
        globalMessage() {
            return this.$store.state.globalMessage
        }
    },
    methods: {
        changeRouteTitle(title) {
            this.routeTitle = title
        },
        updateNavbarColor(val) {
            this.navbarColor = val
            if (val === '#fff') this.isNavbarDark = false
            else this.isNavbarDark = true
        },
        setNavMenuVisibility(layoutType) {
            if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
                this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
            } else {
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
            }
        },
        async getLoggedInCompany() {
            this.currentUser = await profileData.SearchMyProfile();
            if (!this.currentUser) {
                this.$router.push("/completeprofile").catch(() => {})
                return;
            }

            if (this.currentUser.azureObjectId === '00000000-0000-0000-0000-000000000000') {
                this.$router.push("/completeprofile").catch(() => {})
                return;
            }

            if (this.currentUser.userType === 'Customer') {
                this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', "horizontal")
                this.navMenuItems = menuItems.generalMenu;
            }

            if (this.currentUser.userType === 'Administrator' || this.currentUser.userType === 'User') {
                this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', "vertical")
                const newMenu = [];
                const userType = this.currentUser.userType;
                menuItems.barringtonMenu.filter(function (el) {
                    const menu = []
                    if (el.items) {
                        el.items.filter((item) => {
                            if (item.role && item.role.includes(userType)) {
                                menu.push(item);
                            }
                        })
                    }
                    el = {
                        ...el,
                        items: menu
                    }
                    newMenu.push(el)
                })
                this.navMenuItems = newMenu;
            }
        }
    },
    async created() {
        const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
        this.updateNavbarColor(color)
        this.setNavMenuVisibility(this.$store.state.mainLayoutType)
        await this.getLoggedInCompany();
    },
}
</script>
