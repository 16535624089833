const barringtonMenu = [
  {
    url: "/admin/home",
    name: "Overview",
    slug: "home",
    icon: "HomeIcon",
    role: ["User", "Administrator"]
  },
  {
    header: "Accounts & Vehicles",
    icon: "PackageIcon",
    items:[
      {
        url: "/admin/ActiveRegistration",
        name: "All Season Passes",
        slug: "activeReg",
        icon: "CircleIcon",
        role: ["Administrator","User"]
      },
      {
        url: "/admin/PendingRegistration",
        name: "Pending Season Passes",
        slug: "pendingReg",
        icon: "CircleIcon",
        role: ["Administrator","User"]
      },
      {
        url: "/admin/processingRegistration",
        name: "Processing Season Passes",
        slug: "processingReg",
        icon: "CircleIcon",
        role: ["User", "Administrator"]
      },
      {
        url: "/admin/Customers",
        name: "Customers",
        slug: "customer",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
      {
        url: "/VisitHistory",
        name: "Visit History",
        slug: "VisitHistory",
        icon: "CircleIcon",
        role: ["Administrator"]
      }
    ]
  },
  {
    header: "Ballots",
    icon: "PackageIcon",
    items:[
      {
        url: "/admin/ballot",
        name: "Ballots",
        slug: "AdminBallot",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
    ]
  },
  {
    header: "Maintenance",
    icon: "PackageIcon",
    items:[
      {
        url: "/admin/seasonpass",
        name: "Season Pass",
        slug: "profile",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
      {
        url: "/admin/localpostcode",
        name: "Postcodes",
        slug: "Localpostcode",
        icon: "CircleIcon",
        role: ["Administrator","User"]
      },
      {
        url: "/admin/blacklist",
        name: "Blacklist Vehicles",
        slug: "blacklist",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
      {
        url: "/user",
        name: "Users",
        slug: "user",
        icon: "UserIcon",
        role: ["Administrator"]
      },
      {
        url: "/admin/promo",
        name: "Promo Code",
        slug: "promo",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
    ]
  },
  {
    header: "Reports",
    icon: "PackageIcon",
    items:[
      {
        url: "/admin/Financereport ",
        name: "Sales By Product",
        slug: "Financereport ",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
      {
        url: "/admin/CustomerReport",
        name: "Customer Report",
        slug: "CustomerReport ",
        icon: "CircleIcon",
        role: ["Administrator"]
      },
    ]
  },
];

const generalMenu = [
  {
    url: "/MyProfile",
    name: "Profile",
    slug: "profile",
    icon: "CircleIcon"
  },
  {
    url: "/Vehicles",
    name: "Season Pass",
    slug: "Vehicles",
    icon: "CircleIcon"
  },
  {
    url: "/ParkingHistory",
    name: "Visit History",
    slug: "ParkingHistory",
    icon: "CircleIcon"
  },
  {
    url: "/OpenBallot",
    name: "Open Ballots",
    slug: "OpenBallot",
    icon: "CircleIcon"
  },
];

export const menuItems = {
  barringtonMenu,
  generalMenu
};
